import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from '../PreviewCompatibleImage'
import Button from '../Button'
import StringToClassName from "../StringToClassName"

class ThemesListTemplate extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <section className="themesList grid--full">
        {posts && posts.map(({ node: post }) => (
            <article key={post.id} className={'themesList__theme themeTitle--' + StringToClassName(post.frontmatter.themeTitle)}>
                <figure className="themesList__themeImage">
                  <Link to={post.fields.slug} >
                    {post.frontmatter.themeImage ? (
                      <PreviewCompatibleImage
                          imageInfo={{
                            image: post.frontmatter.themeImage,
                            alt: `featured image thumbnail for post ${post.frontmatter.themeTitle}`
                          }}
                        />
                    ) : null}
                  </Link>
                </figure>
                <header className="themesList__themeHeader">
                  <Link to={post.fields.slug} >
                    <p className="lead textJapanese">
                      {post.frontmatter.japaneseTitle}
                    </p>
                    <h2 className="themeTitle">
                      {post.frontmatter.themeTitle}
                    </h2>
                  </Link>
                </header>
                <div className="themesList__themeIntro">
                  <div className="intro">
                    {post.frontmatter.themeIntro}
                  </div>
                  <div className="learnMore">
                    <Button label="Explore" to={post.fields.slug} type="forward" />
                  </div>
                </div>
                <div className="themesList__themeInsights">
                  {post.frontmatter.themeInsights && post.frontmatter.themeInsights.map((insight) => (  
                    <div className="insight">
                      <p className="insightNumber">{insight.insightNumber}</p>
                      <p className="insightTitle">{insight.insightTitle}</p>
                    </div>
                  ))}
                </div>
            </article>
        ))}
      </section>
    )
  }
}

ThemesList.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.shape({
        node: PropTypes.shape({
          frontmatter: PropTypes.shape({
            themeImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
            themeInsights: PropTypes.shape({
              insightIntroImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
              behaviorsList: PropTypes.shape({
                signalsList: PropTypes.shape({
                  signalImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
                }),
              }),
            }),
          }),
        }),
      }),
    }),
  }),
}


export default function ThemesList() {
  return (
    <StaticQuery
      query={graphql`
        query ThemesListQuery {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { templateKey: { eq: "theme-post" } } }
          ) {
            edges {
              node {
                excerpt(pruneLength: 400)
                id
                fields {
                  slug
                }
                frontmatter {
                  themeTitle
                  japaneseTitle
                  themeIntro
                  themeInsights {
                    insightTitle
                    insightNumber
                  }
                  themeImage {
                    childImageSharp {
                      gatsbyImageData(
                        width: 300
                        quality: 100
                        layout: FULL_WIDTH
                        aspectRatio: 1
                      )
                    }
                  }
                  templateKey
                }
              }
            }
          }
        }
      `}
      render={(data, count) => <ThemesListTemplate data={data} count={count} />}
    />
  );
}
