import * as React from "react";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import ThemesList from "../../components/listings/ThemesList";
import StringToClassName from "../../components/StringToClassName";

export default class ThemesIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <body className="page--themesList" />
        </Helmet>
        <div className="articleWrapper articleType--themesList">
          <header className="pageHeader pageHeader--noImg grid--narrow">
            <h1>Themes &amp; Insights</h1>
            <p className="lead">
              This research identified 11 insights that are grouped into 3 themes. These themes bring to life the way Japanese consumers immerse themselves in music, connect with other fans and express their devotion to artists.
            </p>
          </header>
          <main className="pageMain">
            <ThemesList />
          </main>
        </div>
      </Layout>
    );
  }
}
