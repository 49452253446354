import React from 'react';
import { Link } from 'gatsby'

const Button = (props) => {
    const to = props.to;
    const label = props.label;
    const type = props.type;

    return (
        <div className={`linkButton ${type ? "buttonType--" + type : ""}`}>
            <Link to={ to }>
                { label }
            </Link>
        </div>
    );
}

export default Button;